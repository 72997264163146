<template>
    <div :class="[{largeLogo : isLarge}, {smallLogo : !isLarge}]" class="flex items-center lg:block">
        <img src="./../assets/logo.png" alt="musa – museum architektury" :class="[{'cursor-pointer' : isLarge}]">
    </div>
</template>

<script>
export default {
    name: 'logo',
    components: {},
    props:{
        isLarge: Boolean,
    },
}
</script>

<style>
    .largeLogo{
        transform: scale(1.4) translate(calc(50vw - 88%),calc(50vh - 150%));
    }
    .smallLogo{
        transform: scale(1) translate(0,0);
        transition: transform 0.8s linear;
    }
    @media only screen and (max-width: 1024px) {
        .largeLogo{
            transform: scale(2) translate(calc(50vw - 120%),calc(25vh - 50%));
        }
    }
</style>