<template>
  <div id="parent" class="relative w-screen min-h-screen">

    <div id="background">
      <div id="background-filter"
        :class="[{'background-filter-dimmed': isBgDimmed}]">
      </div>
    </div>

    <div :class="[{'sidebar-bg-show': isBgDimmed}]" class="grid grid-cols-3 p-6 lg:block sidebar-width lg:p-5 lg:h-screen fixed top-0 z-10 sidebar-bg sidebar-bg">
      <logo @click="closeWelcome()" :isLarge='this.isLogoLarge'></logo>
      <div class="lg:none">
      </div>
      <navigation :class="[{'opacity-100': isBgDimmed}]" class="opacity-0 show-transition"></navigation>
    </div>

    <div id="view-container" :class="[{'opacity-100': isBgDimmed}]" class="opacity-0 show-transition mt-28 lg:mt-0 p-6 lg:p-12">
      <view-component></view-component>
    </div>
    
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import logo from './components/logo.vue'
import navigation from './components/navigation.vue'
import viewComponent from './components/view-component.vue' 

export default {
  name: 'App',
  components: {
    viewComponent,
    logo,
    navigation,
  },
  data(){
    return{
      isLogoLarge: true, 
      isBgDimmed: false, 
      seenWelcome: false, 
    }
  },
  mounted(){
    setTimeout(()=>{
      if(this.seenWelcome == true) return
      this.closeWelcome()
    },4000) //4000

  },
  methods: {
    closeWelcome: function(){
      if(this.seenWelcome == true) return
      $(window).scrollTop(0);
      this.isLogoLarge = !this.isLogoLarge
      this.isBgDimmed = !this.isBgDimmed
      this.seenWelcome = true

      if ($(window).width() > 1024)
      $('#view-container').css('marginTop',$('#nav li:first-child a').offset().top-parseFloat($('#view-container').css('paddingTop')));
    },
  },
}
</script>

<style>
  #background{
    position: fixed;
    z-index: -100;
    top: 0;
    left: 0;
    background-image: url('~@/assets/background.jpg');
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: cover;
  }
  #background-filter{
    height: 100vh;
    width: 100vw;
    background-color: rgba(255,255,255,0.1);
    transition: background-color 0.8s cubic-bezier(0.5, 0.11, 0.74, 0.69);
    /* transition-delay: 0.8s; */
  }
  .background-filter-dimmed{
    background-color: rgba(255,255,255,0.8) !important;
  }
  .sidebar-bg{
    background-color: rgba(255,255,255,0);
    transition: background-color 0.8s cubic-bezier(0.5, 0.11, 0.74, 0.69);
    transition-delay: 0.8s;
  }
  .sidebar-bg-show{
    background-color: rgba(255,255,255,1) !important;
  }
  .show-transition{
    transition: opacity 0.8s cubic-bezier(0.5, 0.11, 0.74, 0.69);
    transition-delay: 0.8s;
  }
  @media only screen and (min-width: 1024px) {
    .sidebar-width{
      width: 30%;
    }
    #view-container{
      position: absolute;
      top: 0;
      left: 30vw;
      width: 70%;
    }
    .sidebar-bg{
      background: none !important;
    }
  }
  
</style>
