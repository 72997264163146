<template>
    <section id="nav">
        <div class="container flex justify-between h-12 lg:h-auto">
            <!-- Open button for mobile links -->
            <svg @click="navOpen = !navOpen" class="cursor-pointer lg:hidden my-auto ml-auto" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 21">
                <g transform="translate(-411.515 -13)">
                    <line x2="21" transform="translate(413.015 14.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="3"/>
                    <line x2="21" transform="translate(413.015 23.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="3"/>
                    <line x2="21" transform="translate(413.015 32.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="3"/>
                </g>
            </svg>
            <!-- Desktop links -->
            <ul class="hidden lg:flex flex-col my-auto mt-2">
                <li v-for="navRoute in navRoutes.slice(0,-1)" :key="navRoute.path" class="desktop-li block leading-loose uppercase my-2 ml-0">
                    <router-link :to="navRoute.path">{{navRoute.name}}</router-link>
                </li>
            </ul>
            <!-- Mobile links -->
            <transition name="slide">
                <div v-if="navOpen" id="mobile-links" class="block lg:hidden absolute w-screen h-screen left-0 top-0 z-10">
                    <ul class="mobile-ul float-right flex flex-col bg-ct-black h-screen w-full sm:w-1/2 text-xl md:text-2xl my-auto bg-white">
                        <li class="lg:hidden h-20 flex flex-col justify-center ml-auto mr-5">
                            <svg @click="navOpen = !navOpen" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 19.243 19.243">
                                <g transform="translate(-352.379 -19.379)">
                                    <line y1="15" x2="15" transform="translate(354.5 21.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="3"/>
                                    <line x1="15" y1="15" transform="translate(354.5 21.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="3"/>
                                </g>
                            </svg>
                        </li>
                        <li @click="navOpen = !navOpen" v-for="navRoute in navRoutes.slice(0,-1)" :key="navRoute.path" class="inline leading-loose mx-6 uppercase my-2">
                            <router-link :to="navRoute.path">{{navRoute.name}}</router-link>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </section>
</template>

<script>
import navRoutes from "../router/routes";

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
    name: 'navigation',
    data(){
        return{
            navOpen: false,
            navRoutes: navRoutes
        }
    },
    mounted(){
        $(window).on("load resize", () => {
            $(".mobile-ul").css("paddingRight",$(".container").css("marginRight")).css("paddingLeft",$(".container").css("marginLeft"));
        });
    },
    watch: {
        navOpen() {
            setTimeout(()=>{
                $(".mobile-ul").css("paddingRight",$(".container").css("marginRight")).css("paddingLeft",$(".container").css("marginLeft"));
            },1);
        }
    }
}
</script>

<style scoped>
    .slide-enter-active{
        animation: slide 0.7s;
    }
    .slide-leave-active{
        animation: slide 0.7s reverse;
    }
    @keyframes slide {
        0%{
            transform: translateX(100%);
        }
        100%{
            transform: translateX(0);
        }
    }
    .desktop-li{
        transition: margin-left 0.4s;
    }
    .desktop-li:hover{
        margin-left: 1.5rem;
    }
</style>