export default [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "js/Museum" */ "../views/Museum"),
        name: 'museum architektury'
    },
    {
        path: '/manifest',
        component: () => import(/* webpackChunkName: "js/Manifest" */ "../views/Manifest"),
        name: 'manifest'
    },
    {
        path: '/lokalita',
        component: () => import(/* webpackChunkName: "js/Lokalita" */ "../views/Lokalita"),
        name: 'lokalita'
    },
    {
        path: '/budova',
        component: () => import(/* webpackChunkName: "js/Budova" */ "../views/Budova"),
        name: 'budova'
    },
    {
        path: '/instituce',
        component: () => import(/* webpackChunkName: "js/Instituce" */ "../views/Instituce"),
        name: 'instituce'
    },
    {
        path: '/architektonicky-projekt',
        component: () => import(/* webpackChunkName: "js/ArchProjekt" */ "../views/ArchProjekt"),
        name: 'architektonický projekt'
    },
    {
        path: '/organizacni-struktura',
        component: () => import(/* webpackChunkName: "js/OrganizacniStruktura" */ "../views/OrganizacniStruktura"),
        name: 'organizační struktura'
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "js/notFound" */ "../views/NotFound"),
        name: '404'
    }
];